import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GroupEventRequest } from '../../../types';
import './style.scss';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { declineGroupEventRequest } from '../../../apiCalls/groupEvents';
import Modal from '@mui/material/Modal';
import '../components/styling/card-styling.scss';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../../styling/buttons';
import { selectGroupEventMasterclassName, selectGroupEventWebinarName } from '../../../apiHooks/groupSessionTypeNames';
import { useSelector } from 'react-redux';
type Props = {
  inviteData: GroupEventRequest;
  onDecline: () => void;
  className?: string;
};

const NewInviteCard = ({ inviteData }: Props) => {
  const navigate = useNavigate();
  const { id, interest } = inviteData;
  const { name: interestName } = interest;
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const masterclassName = useSelector(selectGroupEventMasterclassName);
  const webinarName = useSelector(selectGroupEventWebinarName);
  const invitationType = inviteData.type==="masterclass"?masterclassName:webinarName;
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '8px',
    transform: 'translate(-50%, -50%)',
    width: ' 554px',
    height: '200px',
    margin: '9px 29px 217px 76px',
    bgcolor: ' #fff',
    boxShadow: 24,
    p: 4,
  };
  return (
    <div className="masterclass-request-paper-box">
      <Paper key={id} elevation={0}>
        <div>
          <div className="rectangle-icon-container">
            <div className="invitation-rectangle-icon">
              <div className="rectangle-icon-text"> Invitation</div>
            </div>
          </div>

          <div className="dashboard-card__container">
            <img
              className="mastercard-photo-card"
              src={'/masterclass.png'}
              alt={'masterclass'}
            />
            <div className="dashboard-card-header-info">
              <div className="dashboard-card-title">
                <div className="dashboard-card-name">
                  Invitation to host a {invitationType}
                </div>
              </div>
              <div className="invite-card-interest">{interestName}</div>

              <div className="invitation-paper-button-wrapper">
                <div className="dashboard-button-container">
                  <DashboardFilledButton
                    variant="contained"
                    onClick={() => {
                      navigate(`/group-event-explainer?id=${id}`);
                    }}
                  >
                    View Details
                  </DashboardFilledButton>
                </div>
                <div className="dashboard-link-button-container">
                  <DashboardEmptyButton
                    sx={{
                      color: '#ff5f5f',
                    }}
                    onClick={handleOpen}
                  >
                    Decline
                  </DashboardEmptyButton>
                </div>
              </div>
            </div>
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <div className="decline-invite-card-name">
                  Decline Masterclass invite
                </div>
                <div className="decline-invite-container">
                  <div>
                    Are you sure you want to decline this masterclass invite?
                  </div>
                  <div className="cancel-invitation-button-wrapper">
                    <DashboardEmptyButton
                      sx={{ color: '#ff5f5f' }}
                      onClick={async () => {
                        await declineGroupEventRequest({ id });
                        window.location.reload();
                      }}
                    >
                      Confirm decline
                    </DashboardEmptyButton>
                    <DashboardEmptyButton onClick={handleClose}>
                      Cancel
                    </DashboardEmptyButton>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default NewInviteCard;
