import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './style.scss';
import Modal from '@mui/material/Modal';
import BusinessHealthTooltip from '../../atoms/BusinessHealthTooltip.tsx';
import { IoIosClose } from 'react-icons/io';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxWidth: '90vw',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};
const inactiveUserStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 120,
  padding: 5,
  maxWidth: '80vw',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
};
type Props = {
  isOpen: boolean;
  event: any;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const findNumberOfEmployees = (text: string | null) => {
  if (text === null) return null;
  else if (text === 'me') return 'Just Me';
  else if (text === 'other') return 'Other';
  else return text.replace('_', '-');
};
const findStageOfBusiness = (text: string | null) => {
  if (text === null) return null;
  else return text.charAt(0).toUpperCase() + text.slice(1).replace('_', '-');
};
export default function MenteeDetailsModal({
  isOpen,
  event,
  setIsModalOpen,
}: Props) {
  const [open, setOpen] = React.useState(isOpen);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setIsModalOpen(false);
  const number_of_employees = findNumberOfEmployees(
    event.organisation?.number_of_employees || null
  );
  const stage_of_business = findStageOfBusiness(
    event.organisation?.stage_of_business || null
  );

  if (event.organisation === null)
    return (
      <div>
        <Button onClick={handleOpen}>Open modal</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={inactiveUserStyle}>
            <div className="modal-profile-name">Inactive User</div>
          </Box>
        </Modal>
      </div>
    );
  else
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="mentee-modal-container">
              <div className="mentee-modal-info">
                <div className="mentee-modal-close">
                  <button className="mentee-modal__header-button">
                    <IoIosClose size={25} onClick={handleClose} />
                  </button>
                </div>

                <div className="organisation-information-photo">
                  <div
                    className="modal-profile-photo-card"
                    style={{
                      backgroundImage: event.organisation?.profile_picture
                        ? `url(${event.organisation.profile_picture})`
                        : 'url(/avatar-dark.png)',
                    }}
                  ></div>
                  <div className="modal-profile-photo-info">
                    <div className="modal-profile-name">
                      {' '}
                      {event.organisation.first_name}
                    </div>
                    <div className="modal-profile-org-name">
                      {event.organisation.name}
                    </div>

                    {event.organisation.business_health ? (
                      <BusinessHealthTooltip
                        business_health={event.organisation.business_health}
                      />
                    ) : null}
                  </div>
                </div>
                {event.organisation.industry?.name ? (
                  <div>
                    <div className="mentee-modal-subheader-text">
                      Organisation Sector
                    </div>
                    <div className="mentee-modal-text">
                      {' '}
                      {event.organisation.industry?.name}
                    </div>
                  </div>
                ) : null}
                {event.organisation.org_type ? (
                  <div className="mentee-modal-subheader-text">
                    Organisation type
                  </div>
                ) : null}
                {event.organisation.org_type ? (
                  <div className="mentee-modal-text">
                    {event.organisation.org_type.charAt(0).toUpperCase() +
                      event.organisation.org_type.slice(1)}
                  </div>
                ) : null}
                {number_of_employees ? (
                  <div className="mentee-modal-subheader-text">
                    Number of employees
                  </div>
                ) : null}
                {number_of_employees ? (
                  <div className="mentee-modal-text">{number_of_employees}</div>
                ) : null}

                {stage_of_business ? (
                  <div className="mentee-modal-subheader-text">
                    Stage of Business
                  </div>
                ) : null}
                {stage_of_business ? (
                  <div className="mentee-modal-text">{stage_of_business}</div>
                ) : null}
                {event.organisation?.disabilities ? (
                  <div className="mentee-modal-subheader-text">Disability</div>
                ) : null}
                {event.organisation?.disabilities
                  ? event.organisation.disabilities.map(
                      (disability: { id: number; name: string }) => (
                        <div className="mentee-modal-text" key={disability.id}>
                          {disability.name}
                        </div>
                      )
                    )
                  : null}
                {event.organisation?.disability_info ? (
                  <div className="mentee-modal-text">
                    {event.organisation?.disability_info}
                  </div>
                ) : null}
                <div className="mentee-modal-subheader-text">
                  Mentee timezone
                </div>
                <div className="mentee-modal-text">
                  {event.organisation.timezone}
                </div>
                {event.organisation.website ? (
                  <div className="mentee-modal-subheader-text"> Website</div>
                ) : null}
                {event.organisation.website ? (
                  <div className="mentee-modal-text">
                    <a
                      href={event.organisation.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mentee-modal-text"
                    >
                      {' '}
                      {event.organisation.website.slice(0, 41)}
                    </a>
                  </div>
                ) : null}

                {event.organisation.linkedin_profile ? (
                  <div>
                    <div className="mentee-modal-subheader-text">
                      Learn more about me on
                    </div>
                    <div
                      className="mentee-modal-text"
                      onClick={() => {
                        window.open(event.organisation.linkedin_profile);
                      }}
                    >
                      LinkedIn
                      <img height="15px" src="/linkedin.png" alt={'linkedIn'} />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="about-me-modal-container">
                <div className="mentee-modal-subheader-text">About me</div>
                <div className="mentee-modal-text">
                  {event.organisation.summary}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
}
