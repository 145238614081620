import React, { useEffect, useState } from 'react';
import './styling/bookings.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import NewUpcomingCard from '../NewUpcomingCard';
import { styled } from '@mui/material/styles';
import {
  CustomMobileRequestsLabel,
  CustomRequestsLabel,
  LoadMoreButton,
} from '../styling';
import BookingsEmptyPage from './bookingsEmptyPage';
import NewCompletedCard from '../NewCompletedCard';
import NewRecordingCard from '../NewRecordingCard';
import { Badge } from '@mui/material';
import NewRescheduleCard from '../NewRescheduleCard';
import useWindowSize from '../../../hooks/useWindowSize';
import ManageCallPage from '../../../pages/NewCallDetails/manageCallPage';
import useSelectProfileData from '../../../hooks/useSelectProfileData';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface Props {
  upcoming: Array<any>;
  completed: Array<any>;
  groupEventsCompleted: any[];
  timeZoneString?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const BookingsTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'primary',
  },
});

const Bookings = ({
  upcoming,
  completed,
  groupEventsCompleted,
  timeZoneString = '',
}: Props) => {
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const [upcomingCounter, setUpcomingCounter] = useState(4);
  const [completedCounter, setCompletedCounter] = useState(4);
  const [displayEventUpcomingDetails, setDisplayUpcomingEventDetails] =
    useState(null);
  const [valueTab, setValueTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };
  const addArraytoArray = (arr1: any[], arr2: any[]) => {
    let i = 0;
    while (i < arr2.length) {
      arr1.push(arr2[i]);
      i++;
    }
    return arr1;
  };
  const [hideGroupEvents, setHideGroupEvents] = useState<boolean>(true);
  const { volunteerProfile, hasFinishedFetchingProfileAndLocation } =
    useSelectProfileData();
  useEffect(() => {
    if (
      hasFinishedFetchingProfileAndLocation &&
      (volunteerProfile?.partner === null ||
        volunteerProfile?.partnerInfo?.hide_group_events === false)
    ) {
      setHideGroupEvents(false);
    }
  }, [hasFinishedFetchingProfileAndLocation, volunteerProfile]);

  const sortByPriority = (events: any[]) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    var topPriorityRequests = events.filter(
      (event) =>
        event.status === 'scheduled' && new Date(event.start_time) <= tomorrow
    );
    topPriorityRequests = topPriorityRequests?.sort(
      (event1: any, event2: any) =>
        new Date(event1.start_time).valueOf() -
        new Date(event2.start_time).valueOf()
    );
    var secondPriorityRequests = events.filter(
      (event) => event.reschedule_status === 'pending'
    );
    secondPriorityRequests = secondPriorityRequests?.sort(
      (event1: any, event2: any) =>
        new Date(event1.start_time).valueOf() -
        new Date(event2.start_time).valueOf()
    );
    var thirdPriority = events.filter(
      (event) =>
        event.status === 'scheduled' &&
        event.reschedule_status !== 'pending' &&
        new Date(event.start_time) > tomorrow
    );
    thirdPriority = thirdPriority?.sort(
      (event1: { start_time: string }, event2: { start_time: string }) =>
        new Date(event1.start_time).valueOf() -
        new Date(event2.start_time).valueOf()
    );
    const top3Requests = [
      ...topPriorityRequests,
      ...secondPriorityRequests,
      ...thirdPriority,
    ];
    const remainingEvents = events.filter(
      ({ id: id1 }) => !top3Requests.some(({ id: id2 }) => id2 === id1)
    );

    var finalEventsArray: any[] = [];
    addArraytoArray(finalEventsArray, topPriorityRequests);
    addArraytoArray(finalEventsArray, secondPriorityRequests);
    addArraytoArray(finalEventsArray, thirdPriority);
    addArraytoArray(finalEventsArray, remainingEvents);

    //remove duplicates
    finalEventsArray = finalEventsArray.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
    return finalEventsArray;
  };

  const displayEvents = (value: any) => {
    if (value === 0) return displayScheduledEvents();
    else if (value === 1) return displayCompletedEvents();
    else return displayMasterclassRecordings();
  };
  const displayMasterclassRecordings = () =>
    groupEventsCompleted.map((event, index) =>
      event.recording_url ? (
        <NewRecordingCard
          timeZone={timeZoneString}
          appointmentData={event}
          subject="Upcoming"
        />
      ) : null
    );

  const displayCompletedEvents = () =>
    completed
      .slice(0, completedCounter)
      .sort(
        (event1: { start_time: string }, event2: { start_time: string }) =>
          new Date(event2.start_time).valueOf() -
          new Date(event1.start_time).valueOf()
      )
      .map((event, index) =>
        event.status === 'complete' &&
        event.title === '1-to-1 mentoring call' ? (
          <NewCompletedCard
            timeZone={timeZoneString}
            appointmentData={event}
            subject="Upcoming"
          />
        ) : null
      );

  const bookingsBadge = () => (
    <Badge
      sx={{
        marginLeft: '15px',
        '& .MuiBadge-badge': {
          color: 'white',
          backgroundColor: '#ff5f5f',
        },
      }}
      badgeContent={
        upcoming.filter((event) => event.organisation !== null).length
      }
    />
  );

  const displayScheduledEvents = () =>
    sortByPriority(upcoming)
      .slice(0, upcomingCounter)
      .filter((event) => event.organisation !== null)
      .map((event, index) => {
        if (event.reschedule_status === 'pending')
          return (
            <NewRescheduleCard
              key={index}
              timeZone={timeZoneString}
              appointmentData={event}
            />
          );
        else if (event.status === 'scheduled')
          return (
            <NewUpcomingCard
              key={index}
              timeZone={timeZoneString}
              appointmentData={event}
              subject="Upcoming"
            />
          );
        else return null;
      });
  if (displayEventUpcomingDetails)
    return (
      <ManageCallPage
        event={displayEventUpcomingDetails}
        setDisplayUpcomingEventDetails={setDisplayUpcomingEventDetails}
      />
    );
  else
    return (
      <div className="dashboard-bookings-container">
        <div className="dashboard-title-component">Bookings</div>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <BookingsTabs
              value={valueTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ width: '35%' }}
                value={0}
                label={
                  !isMobile ? (
                    <CustomRequestsLabel>
                      Upcoming {bookingsBadge()}
                    </CustomRequestsLabel>
                  ) : (
                    <CustomMobileRequestsLabel>
                      Upcoming {bookingsBadge()}
                    </CustomMobileRequestsLabel>
                  )
                }
              />
              <Tab
                sx={{ width: '30%' }}
                value={1}
                label={
                  !isMobile ? (
                    <CustomRequestsLabel>Completed</CustomRequestsLabel>
                  ) : (
                    <CustomMobileRequestsLabel>
                      Completed
                    </CustomMobileRequestsLabel>
                  )
                }
              />
              {!hideGroupEvents ? (
                <Tab
                  sx={{ width: '40%' }}
                  value={2}
                  label={
                    !isMobile ? (
                      <CustomRequestsLabel>
                        Masterclass Replays
                      </CustomRequestsLabel>
                    ) : (
                      <CustomMobileRequestsLabel>
                        Masterclass Replays
                      </CustomMobileRequestsLabel>
                    )
                  }
                />
              ) : null}
            </BookingsTabs>
          </Box>
          <TabPanel value={valueTab} index={0} key={0}>
            {upcoming.length > 0 ? (
              <div className="tab-panel-bookings-container">
                {displayEvents(0)}
                <div className="loading-button-container">
                  {upcomingCounter < upcoming.length ? (
                    <LoadMoreButton
                      disabled={upcomingCounter >= upcoming.length}
                      variant="contained"
                      onClick={() => setUpcomingCounter(upcomingCounter + 4)}
                    >
                      Load more
                    </LoadMoreButton>
                  ) : null}
                </div>
              </div>
            ) : (
              <BookingsEmptyPage tab={valueTab} />
            )}
          </TabPanel>
          <TabPanel value={valueTab} index={1} key={1}>
            {completed.length > 0 ? (
              <div className="tab-panel-bookings-container">
                {displayEvents(1)}
                <div className="loading-button-container">
                  {completedCounter < completed.length ? (
                    <LoadMoreButton
                      variant="contained"
                      onClick={() => setCompletedCounter(completedCounter + 4)}
                    >
                      Load more
                    </LoadMoreButton>
                  ) : null}
                </div>
              </div>
            ) : (
              <BookingsEmptyPage tab={valueTab} />
            )}
          </TabPanel>
          <TabPanel value={valueTab} index={2} key={2}>
            {groupEventsCompleted.length > 0 ? (
              <div className="tab-panel-bookings-container">
                {displayEvents(2)}
              </div>
            ) : (
              <BookingsEmptyPage tab={valueTab} />
            )}
          </TabPanel>
        </Box>
      </div>
    );
};

export default Bookings;
