import moment from 'moment';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { DashboardFilledButton } from '../../styling/buttons';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import './style.scss';
import NewAddToCalendarButton from '../../components/NewAddToCalendarButton';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from '../../apiHooks/sessionTitle';

type Props = {
  event: any;
};
const CallConfirmedPage = ({ event }: Props) => {
  const { userLocation, volunteerProfile, hasFinishedLoadingVolunteerProfile } =
    useSelectProfileData();
  if (
    hasFinishedLoadingVolunteerProfile &&
    volunteerProfile?.partnerInfo?.name !== undefined &&
    volunteerProfile?.partnerInfo.white_label
  )
    event.partner_name = volunteerProfile?.partnerInfo?.name;
  const session_title = useSelector(selectSessionTitle);
  event.session_title = session_title;
  const timeZoneString =
    userLocation && userLocation.timezone ? userLocation.timezone : '';
  const navigate = useNavigate();
  const sessionTitle = useSelector(selectSessionTitle);
  return (
    <div className="call-confirmation">
      <div className="call-confirmation-title">
        Your {sessionTitle} is confirmed!
      </div>
      <div className="confirmation-avatar">
        <img
          src={'/confirmation-avatar.png'}
          height="300px"
          alt="confirmation-avatar"
        />
      </div>
      <div className="call-confirmation-header-text">
        {sessionTitle} with {event.organisation.first_name}
      </div>
      <div className="date-time-confirmation-call">
        {moment(event.start_time).format('MMMM Do YYYY, HH:mm')}
      </div>
      <div className="confirmation-text">
        We have sent you a confirmation email and have added it to your
        dashboard. Make sure to add it to your calendar.
      </div>
      <div className="confirmation-avatar">
        <NewAddToCalendarButton
          callInfo={event}
          timeZoneString={timeZoneString}
        />
      </div>
      <DashboardFilledButton
        variant="contained"
        sx={{ width: '182px', alignSelf: 'center' }}
        onClick={() => navigate('/dashboard')}
      >
        Go to Dashboard
      </DashboardFilledButton>
    </div>
  );
};

export default memo(CallConfirmedPage);
