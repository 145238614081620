import React, { useEffect, useState } from 'react';
import './statistics.scss';
import { IconButton, Paper, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import useGetGroupEventCompleted from '../../../apiHooks/groupEvents/useGetGroupEventCompleted';
import useGetTotalOrgsHelped from '../../../apiHooks/stats/useGetTotalOrgsHelped';
import useWindowSize from '../../../hooks/useWindowSize';

export default function StatisticsChart() {
  const { groupEventCompletedData } = useGetGroupEventCompleted({});
  const { totalOrgsHelped } = useGetTotalOrgsHelped();
  const organisationsBadge = (count: number) => {
    if (count < 6) return <div>💪 Going good</div>;
    if (count > 5 && count < 11) return <div>😎 You are committed</div>;
    if (count > 10) return <div>🤩 You are amazing</div>;
  };
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  return (
    <div className="statistics-container">
      <div className="statistics-paper-box">
        <Paper key={1} elevation={0}>
          <div className="statistics-tooltip">
            <Tooltip
              open={showTooltip1}
              onClick={() => setShowTooltip1(true)}
              onClose={() => setShowTooltip1(false)}
              leaveTouchDelay={isMobile ? 5000 : 1500}
              placement={isMobile ? 'top-start' : 'right'}
              title="These are the numbers of organisations you helped so far."
            >
              <IconButton sx={{ fontSize: 'small' }}>
                <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className="statistics-paper-container">
            <div className="organisations-helped-photo-card-container">
              <img
                className="organisations-helped-photo-card"
                src={'/courthouse.png'}
                alt={'organisations-helped'}
              />
            </div>
            <div className="statistics-paper-info">
              <div className="statistics-count">{totalOrgsHelped || 0}</div>
              <div className="statistics-reward">
                <div className="statistics-title">
                  Organisations helped
                  <div className="chart-text-helper">Since joined</div>
                </div>
                <div className="statistics-badge">
                  {totalOrgsHelped ? organisationsBadge(totalOrgsHelped) : null}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
      <div className="statistics-paper-box">
        <Paper key={2} elevation={0}>
          <div className="statistics-tooltip">
            <Tooltip
              open={showTooltip2}
              onClick={() => setShowTooltip2(true)}
              onClose={() => setShowTooltip2(false)}
              leaveTouchDelay={isMobile ? 5000 : 1500}
              placement={isMobile ? 'top-start' : 'right'}
              title="These are the masterclasses you have hosted."
            >
              <IconButton sx={{ fontSize: 'small' }}>
                <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className="statistics-paper-container">
            <div className="masterclass-statistics-photo-card-container">
              <img
                className="masterclass-statistics-photo-card"
                src={'/masterclass-statistics.png'}
                alt={'organisations-helped'}
              />
            </div>
            <div>
              <div className="statistics-paper-info">
                <div className="statistics-count">
                  {groupEventCompletedData?.length || 0}
                </div>
                <div className="statistics-reward">
                  <div className="statistics-title">
                    Masterclasses hosted
                    <div className="chart-text-helper">Since joined</div>
                  </div>

                  <div className="statistics-badge">
                    {groupEventCompletedData &&
                    groupEventCompletedData.length > 0
                      ? organisationsBadge(groupEventCompletedData.length)
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}
