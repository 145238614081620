import { configureStore } from '@reduxjs/toolkit';
import auth from './apiHooks/auth';
import userProfile from './apiHooks/userProfile';
import partnerProfile from './apiHooks/partnerProfile';
import volunteerProfile from './apiHooks/volunteerProfile';
import sessionTitle from './apiHooks/sessionTitle';
import userLocation from './apiHooks/userLocation';
import boostCallEvents from './apiHooks/boostCallEvents';
import messages from './apiHooks/messages';
import messagingCentre from './apiHooks/messagingCentre';
import theme from './redux/theme';
import attachments from './redux/attachments/attachments';
import groupSessionTypeNames from './apiHooks/groupSessionTypeNames';

export const store = configureStore({
  reducer: {
    auth,
    sessionTitle,
    userProfile,
    partnerProfile,
    userLocation,
    attachments,
    volunteerProfile,
    boostCallEvents,
    messages,
    messagingCentre,
    theme,
    groupSessionTypeNames
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
  },
  // we need to double-check that we're handling NODE_ENV correctly
  // https://docs.netlify.com/configure-builds/manage-dependencies/#node-js-environment
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
