import React from 'react';
import { useNavigate } from 'react-router-dom';
import GroupEventAgendaCard from '../../components/GroupEventAgendaCard';
import { getUrlParam } from '../../utils/url';
import { toTitleCase } from '../../utils/text';
import useGetGroupEventInvite from '../../apiHooks/groupEvents/useGetGroupEventRequests';
import { GroupEventRequest } from '../../types';
import './group-event-explainer.scss';
import { CustomMasterclassTypography } from '../../styling/generalStyling';
import { DashboardFilledButton } from '../../styling/buttons';
import { useSelector } from 'react-redux';
import { selectGroupEventMasterclassName, selectGroupEventWebinarName } from '../../apiHooks/groupSessionTypeNames';
type TextByStatus = {
  [status in GroupEventRequest['status']]: string;
};

const titleTextByStatus = (
  status: keyof TextByStatus,
  eventType: string
): string => {
  return (
    {
      invited: `Invitation`,
      accepted: `You have already accepted this ${eventType} invitation`,
      declined: `You have already decline this ${eventType} invitation`,
      cancelled: `This ${eventType} invitation is no longer available`,
      expired: `This ${eventType} invitation is no longer available`,
    }[status] || '"Sorry - we could not find this event invitation"'
  );
};
const descriptionTextByStatus = (
  status: keyof TextByStatus,
  eventType: string,
  subject: string
): string => {
  return (
    {
      invited: `Thank you so much for volunteering with Digital Boost! 🤗 \nYou’ve received really positive feedback so far and we’d love to have you host a ${eventType} about ${subject}!`,
      accepted: `Thank you again for accepting our invitation!\nWe look forward to your ${eventType} about ${subject}.`,
      declined: `Thank you again for considering our invitation.`,
      cancelled: `Thank you again for considering our invitation.`,
      expired: `Thank you again for considering our invitation.`,
    }[status] || ''
  );
};

const GroupEventAvailability = () => {
  const navigate = useNavigate();
  const requestId = getUrlParam('id') as string;
  if (!requestId) window.location.href = '/dashboard';
  const {
    groupEventRequestData,
    groupEventRequestError,
    hasFinishedLoadingGroupEventRequest,
  } = useGetGroupEventInvite({ id: requestId });
  const groupEventRequest = groupEventRequestData && groupEventRequestData[0];
  const { id, interest, status, type } = groupEventRequest || {};
  const { name: interestName = '' } = interest || {};
  const masterclassName = useSelector(selectGroupEventMasterclassName)||'Masterclass';
  const webinarName = useSelector(selectGroupEventWebinarName)||'Webinar';
  const eventType = type==="masterclass"?masterclassName:webinarName;

  const conceptTitleText = `What is a ${eventType}?`;
  const conceptText = `A ${eventType} is an interactive one-hour session for a group to learn about a topic like ${interestName} and ask questions specific to their needs. All of our events are held virtually on Zoom.`;
  const reasonTextTile = `Why host a ${eventType}?`;
  const reasonText = `Hosting a ${eventType} allows you to share your expertise with a wider group of people. In just one hour, you can help up to 50 learners at once! 🚀 It’s also a great way to practice your presentation skills and meet new people! `;

  const howDoesItWorkTextTile = `How does it work?`;
  const howDoesItWorkText = `On the next screen, you’ll be prompted to submit your availability and have the option to customise the title and description of your ${eventType}, so you can make it as specialised as you like. Once submitted, we’ll review the proposed session and send you a confirmation.`;

  if (groupEventRequestError)
    return (
      <div
        className="group-event-explainer__container"
        style={{ height: '60vh' }}
      >
        <h1 className="error">We're sorry - an error has occurred.</h1>
      </div>
    );

  return hasFinishedLoadingGroupEventRequest && groupEventRequestData ? (
    <div className="group-event-explainer__container">
      <div className="group-event-explainer__title-container">
        <div className="group-event-explainer__title-text-wrapper">
          {status ? (
            <h1 className="group-event-explainer__title">
              {titleTextByStatus(status, eventType)}
            </h1>
          ) : (
            <h1 className="group-event-explainer__title">
              {'Sorry - we could not find this event invitation'}
            </h1>
          )}
          <CustomMasterclassTypography>{`${
            eventType && eventType.toUpperCase()
          } | ${
            interestName && interestName.toUpperCase()
          }`}</CustomMasterclassTypography>
        </div>
      </div>
      <div className="group-event-explainer__body-wrapper">
        {status ? (
          <p className="group-event-explainer__thank-you-text">
            {descriptionTextByStatus(
              status,
              eventType,
              toTitleCase(interestName)
            )}
          </p>
        ) : (
          <p className="group-event-explainer__thank-you-text">
            If you think there has been a mistake, please get in touch with us
            at: <br />
            <a href="mailto:contact@digitalboost.org.uk">
              contact@digitalboost.org.uk
            </a>
          </p>
        )}
        {status === 'invited' && (
          <>
            <h4 className="group-event-explainer__agenda-title">
              {conceptTitleText}
            </h4>
            <p className="group-event-explainer__agenda-text">{conceptText}</p>
            <h4 className="group-event-explainer__agenda-title">
              {reasonTextTile}
            </h4>
            <p className="group-event-explainer__agenda-text">{reasonText}</p>
            <h4 className="group-event-explainer__agenda-title">
              {howDoesItWorkTextTile}
            </h4>
            <p className="group-event-explainer__agenda-text">
              {howDoesItWorkText}
            </p>
          </>
        )}
      </div>
      <br />
      <div className="group-event-explainer__agenda-text">
        We’ve outlined an example {eventType} agenda below but feel free to run
        your session however you like!
      </div>
      <GroupEventAgendaCard
        eventType={eventType}
        isExample
        className="group-event-explainer__agenda-card"
      />

      {status === 'invited' && (
        <>
          <div className="group-event-explainer__button-container">
            <DashboardFilledButton
              onClick={() => navigate(`/group-event-availability?id=${id}`)}
              variant="contained"
              sx={{ width: '360px', marginRight: '40px', maxWidth: '90vw' }}
            >
              Continue: Submit Your Availability
            </DashboardFilledButton>
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="group-event-explainer__loading-container">
      <img
        className="group-event-explainer__loading-spinner"
        src="/loading.gif"
        alt="loading"
      />
    </div>
  );
};

export default GroupEventAvailability;
