import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import AddGroupEventToCalendarButton from '../../atoms/AddGroupEventToCalendarButton';
import GroupEventAgendaCard from '../../components/GroupEventAgendaCard';
import useGetGroupEvent from '../../apiHooks/groupEvents/useGetGroupEvent';
import useGetSpeakerBriefing from '../../apiHooks/groupEvents/useGetSpeakerBriefing';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import { getUrlParam } from '../../utils/url';
import { toTitleCase } from '../../utils/text';
import { Attendee, SpeakerBriefing } from '../../types';
import './group-event-briefing.scss';
import useGetAttendees from '../../apiHooks/groupEvents/useGetAttendees';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { CustomBriefingTypography } from '../../styling/generalStyling';
import { DashboardEmptyButton } from '../../styling/buttons';
import { useSelector } from 'react-redux';
import { selectGroupEventMasterclassName, selectGroupEventWebinarName, selectGroupEventWorkshopName } from '../../apiHooks/groupSessionTypeNames';

const DummyBriefingCard = () => (
  <div className="group-event-briefing__request-card">
    <div className="group-event-briefing__request-profile-container">
      <img
        src={'/avatar.png'}
        alt="mentee"
        className="group-event-briefing__request-profile-picture"
      />
      <div className="group-event-briefing__request-profile-text-container">
        <span className="group-event-briefing__request-profile-name group-event-briefing__request-profile-name--dummy">
          {'Jane Doe Esq.'}
        </span>
        <span className="group-event-briefing__request-profile-org-name group-event-briefing__request-profile-org-name--dummy">
          {'Business Company Inc.'}
        </span>
      </div>
    </div>
    <span className="group-event-briefing__request-body-text group-event-briefing__request-body-text--dummy">
      {
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consequat.'
      }
    </span>
    <span className="group-event-briefing__request-body-text group-event-briefing__request-body-text--dummy">
      {
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo '
      }
    </span>
  </div>
);

const BriefingCard = ({
  attendee,
  speakerBriefingData,
  interest,
}: {
  attendee: Attendee;
  speakerBriefingData?: SpeakerBriefing[];
  interest: string;
}) => {
  const isSpeakerBriefing =
    speakerBriefingData &&
    speakerBriefingData.filter(
      (item: SpeakerBriefing) =>
        item.organisation.id === attendee.organisation.id
    );

  const getExpertiseText = (interestExpertise: string) => {
    switch (interestExpertise) {
      case 'nothing_at_all':
        return 'Nothing at all';
      case 'i_know_some':
        return 'I know some';
      case 'i_know_a_lot':
        return 'I know a lot, just need help with some specifics';
      default:
        return '';
    }
  };

  return (
    <div className="group-event-briefing__request-card">
      <div className="group-event-briefing__request-profile-container">
        <img
          src={attendee.organisation.profile_picture || '/avatar.png'}
          alt="mentee"
          className="group-event-briefing__request-profile-picture"
        />
        <div className="group-event-briefing__request-profile-text-container">
          <span className="group-event-briefing__request-profile-name">
            {attendee.organisation.first_name}
          </span>
          <span className="group-event-briefing__request-profile-org-name">
            {attendee.organisation.name}
          </span>
        </div>
      </div>
      {isSpeakerBriefing && isSpeakerBriefing.length > 0 && (
        <>
          {isSpeakerBriefing[0].interest_expertise && (
            <p className="group-event-briefing__request-expertise-text">
              {`Current confidence level in ${interest}: `}
              <b>
                {`${getExpertiseText(isSpeakerBriefing[0].interest_expertise)}`}
              </b>
            </p>
          )}
          <p className="group-event-briefing__request-body-text">
            {isSpeakerBriefing[0].help_needed}
          </p>
        </>
      )}
    </div>
  );
};

const ToolTip = ({
  eventType,
  hoursToActivateBeforeEventStarts,
  texts,
}: {
  eventType: string;
  hoursToActivateBeforeEventStarts: number;
  texts: string[];
}) => {
  return (
    <div className="group-event-briefing__tooltip-icon-container">
      <img
        className="group-event-briefing__tooltip-icon"
        width={'5px'}
        height="8px"
        src="/question.png"
        alt="tool tip"
      />
      <div className="group-event-briefing__tooltip-text-container">
        {texts.map((t: string) => (
          <span className="group-event-briefing__tooltip-text">{t}</span>
        ))}
      </div>
      <div className="group-event-briefing__tooltip-pointer" />
    </div>
  );
};

const GroupEventBriefing = () => {
  const code = getUrlParam('code') as string;
  const partnerId = getUrlParam('id') as string;
  const joinCallParam = getUrlParam('join_call') as string;
  const navigate = useNavigate();

  const [shouldJoinCall, setShouldJoinCall] = useState(!!joinCallParam);
  if (!code) window.location.href = '/dashboard';
  const {
    groupEventData = [],
    hasFinishedLoadingGroupEvents,
    groupEventError,
  } = useGetGroupEvent({ code });
  const { speakerBriefingData } = useGetSpeakerBriefing({ code });
  const { attendeesData } = useGetAttendees({ code, status: 'accepted' });

  const groupEvent = groupEventData[0];
  const { joinCall } = useGetJoinCall({ callInfo: groupEvent });

  const {
    start_time,
    type: eventType,
    interest,
    duration,
    description,
  } = groupEvent || {};
  const { volunteerProfile, userLocation } = useSelectProfileData();
  const { timezone = '' } = userLocation || {};
  const masterclassName =
    useSelector(selectGroupEventMasterclassName) || 'Masterclass';
  const webinarName = useSelector(selectGroupEventWebinarName) || 'Webinar';
  const workshopName = useSelector(selectGroupEventWorkshopName) || 'Workshop';
  const shareEventType =
  eventType === 'masterclass'
      ? masterclassName
      :   eventType === 'webinar'
      ? webinarName
      : workshopName;

  useEffect(() => {
    if (shouldJoinCall && hasFinishedLoadingGroupEvents) {
      joinCall();
      setShouldJoinCall(false);
    }
  }, [shouldJoinCall, hasFinishedLoadingGroupEvents, joinCall]);

  if (groupEventError)
    return (
      <div
        className="group-event-briefing__container"
        style={{ height: '60vh' }}
      >
        <h1 className="error">We're sorry - an error has occurred.</h1>
      </div>
    );

  if (!start_time || !volunteerProfile || !userLocation || !interest)
    return null;

  const promotionPageLink = partnerId
    ? `/group-event-details?code=${code}&id=${partnerId}`
    : `/group-event-details?code=${code}`;
  const joinCallText = `Join ${shareEventType ? toTitleCase(shareEventType) : 'Call'}`;

  const dateToDisplay = moment(start_time).tz(timezone)?.format('MMMM Do YYYY');
  const timeToDisplay = moment(start_time).tz(timezone)?.format('HH:mm');

  const briefingDescriptionText = `As ${toTitleCase(
    shareEventType
  )} attendees register, you'll see them here.`;
  const guidePDF = `https://digitalboost-community-management.s3.eu-west-2.amazonaws.com/Mentors/Hosting+your+first+Masterclass.pdf`;

  return hasFinishedLoadingGroupEvents ? (
    <div className="group-event-briefing__container">
      <div className="group-event-briefing__title-container">
        <div className="group-event-briefing__title-text-wrapper">
          <h1 className="group-event-briefing__title">Speaker Briefing</h1>
          <CustomBriefingTypography className="group-event-briefing__subtitle">{`${shareEventType.toUpperCase()}: ${interest.name.toUpperCase()}`}</CustomBriefingTypography>
        </div>
      </div>
      <div className="group-event-briefing__body-wrapper">
        <p>{`Thank you so much for agreeing to lead a Digital Boost ${toTitleCase(
          shareEventType
        )} discussion on ${toTitleCase(
          interest.name
        )}. We’re very much looking forward to the session.`}</p>
        <p>{`In just ${duration} minutes you can help participants address issues that have been weighing heavily on their minds and preventing their ability to drive the success of the charity or the small business they work for.`}</p>
        <div className="group-event-briefing__card-container">
          <div className="group-event-briefing__card group-event-briefing__details-card">
            <h2 className={'group-event-briefing__body-title'}>
              {groupEvent?.title || `${toTitleCase(shareEventType)} Details`}
            </h2>
            <div className="group-event-briefing__card-row">
              <span className="group-event-briefing__card-row-1">Date</span>
              <span className="group-event-briefing__card-row-2">
                {dateToDisplay}
              </span>
            </div>
            <div className="group-event-briefing__card-row">
              <span className="group-event-briefing__card-row-1">Time</span>
              <span className="group-event-briefing__card-row-2">
                {timeToDisplay}
              </span>
            </div>
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '100%', marginTop: '10px' }}
              onClick={joinCall}
            >
              {joinCallText}
              <ToolTip
                hoursToActivateBeforeEventStarts={0.5}
                texts={[
                  `You can use this button to join the ${toTitleCase(
                    shareEventType
                  )} shortly before it begins.`,
                  `If you want to test you have Zoom installed correctly, feel free to click and try it out before then.`,
                ]}
                eventType={shareEventType}
              />
            </DashboardEmptyButton>
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '100%', marginTop: '10px' }}
              onClick={() => navigate(promotionPageLink)}
            >
              {toTitleCase(shareEventType)} Promotion Page
              <ToolTip
                hoursToActivateBeforeEventStarts={0.5}
                texts={[
                  `You can share this page to allow anyone to sign-up and register for your ${toTitleCase(
                    shareEventType
                  )}`,
                ]}
                eventType={shareEventType}
              />
            </DashboardEmptyButton>
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '100%', marginTop: '10px' }}
              onClick={() => window.open(guidePDF, '_blank')}
            >
              {`${toTitleCase(shareEventType)} Guide`}
            </DashboardEmptyButton>
            {groupEvent && (
              <AddGroupEventToCalendarButton
                eventInfo={groupEvent}
                timeZoneString={timezone}
              />
            )}
          </div>
          <GroupEventAgendaCard
            eventType={shareEventType}
            className="group-event-briefing__agenda-card"
            description={description}
          />
        </div>

        <h3 className="group-event-briefing__requests-title">
          {briefingDescriptionText}
        </h3>
        <div className="group-event-briefing__requests-container">
          {attendeesData ? (
            attendeesData.map((attendee: Attendee) => (
              <BriefingCard
                attendee={attendee}
                interest={interest?.name}
                speakerBriefingData={speakerBriefingData}
              />
            ))
          ) : (
            <div className="group-event-briefing__dummy-requests-container">
              <DummyBriefingCard />
              <DummyBriefingCard />
              <DummyBriefingCard />
              <div className="group-event-briefing__dummy-requests-overlay" />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="group-event-briefing__loading-container">
      <img
        className="group-event-briefing__loading-spinner"
        src="/loading.gif"
        alt="loading"
      />
    </div>
  );
};

export default GroupEventBriefing;
