import React, { useEffect, useState } from 'react';
import api from '../../api';
import qs from 'qs';
import './nps-score.scss';
import { useNavigate } from 'react-router-dom';
import { getErrorMessageFromResponse } from '../../utils/error';
import TextAreadWithLabel from '../../atoms/TextAreaWithLabel';

import { DashboardEmptyButton } from '../../styling/buttons';

interface Props {
  eventIdFromUser?: number | undefined;
  setDisableAfterFeedbackGiven?: any | undefined;
}

const GroupEventFeedback = ({ setDisableAfterFeedbackGiven }: Props) => {
  const [feedBackAlreadyGiven, setFeedbackAlreadyGiven] =
    useState<boolean>(false);
  const GroupEventCode = qs.parse(
    window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
  ).code;
  const [eventGroupId, setEventGroupId] = useState<any>(null);
  const [feedback, setFeedback] = useState<string>('');
  const [thankYou, setThankYou] = useState<any>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const sendFeedback = async () => {
    const postData = {
      review: feedback,
      group_event: eventGroupId,
    };
    api
      .post(`api/netpromoterscore/groupeventfeedback/`, postData)
      .then((response) => {
        if (response) {
          if (setDisableAfterFeedbackGiven) {
            setDisableAfterFeedbackGiven(true);
          }

          return true;
        }
      })
      .catch((err) => {
        setErrorMessage(getErrorMessageFromResponse(err));
        return false;
      });
  };

  const getGroupEventID = async () => {
    const GroupEventId = (
      await api.get(`api/groupevent/groupevent/${GroupEventCode}`)
    ).data.id;
    setEventGroupId(GroupEventId);
    return new Promise((resolve) => {
      resolve(GroupEventId);
    });
  };

  const getFeedbackGiven = async (id: any) => {
    const isFeedbackGiven = (
      await api.get(
        `api/netpromoterscore/groupeventfeedback/check/?group_event_id=${id}`
      )
    ).data;
    setFeedbackAlreadyGiven(isFeedbackGiven.is_feedback_sent);
  };

  // Handles initial data fetching & handles URL params
  useEffect(() => {
    (async () => {
      try {
        await getGroupEventID().then(async (response) => {
          if (response) {
            await getFeedbackGiven(response);
          }
        });
      } catch (err) {
        setErrorMessage(
          'We are sorry, the page is not available at the moment.'
        );
        return false;
      }
    })();
  });

  if (errorMessage) {
    return (
      <div className="nps-score__thankyou-container">
        <div className="nps_score__thankyou">
          <h1 className="nps-score__error nps-score__title-container">
            {' '}
            {errorMessage}
          </h1>
        </div>
      </div>
    );
  } else if (thankYou) {
    return (
      <div className="nps-score__thankyou-container">
        <div className="nps_score__thankyou">
          <h2>Thank you for your feedback</h2>
        </div>
      </div>
    );
  } else if (feedBackAlreadyGiven) {
    return (
      <div className="nps-score__feedbackalreadygiven-container">
        <div className="nps_score__feedbackalreadygiven">
          <h2>Done!</h2>
          <p>You’ve already given feedback on this event - thank you!</p>
          <div className="nps-score__feedbackalreadygiven-buttons-container">
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '200px', marginTop: '10px' }}
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Back to Dashboard
            </DashboardEmptyButton>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="nps-score">
        <div className="nps-score__container">
          <h2>{`Thank you for leading this Masterclass! How did it go?`}</h2>
          <div className="nps-score__details-container nps-score__mobile-card"></div>
          <div className="nps-score__mobile-card nps-score__feedback-container">
            <TextAreadWithLabel
              onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setFeedback(e.target.value);
              }}
              rows={3}
              cols={4}
              placeHolder={'Your feedback is highly valued.'}
            />
          </div>
          <div className="nps-score__button-container">
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '200px', marginTop: '10px' }}
              onClick={() => {
                navigate('/dashboard/actions');
              }}
            >
              Cancel
            </DashboardEmptyButton>

            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '200px', marginTop: '10px' }}
              onClick={() => {
                sendFeedback().then(() => {
                  window.scrollTo(0, 0);
                  setThankYou(true);
                });
              }}
            >
              Send
            </DashboardEmptyButton>
          </div>
        </div>
      </div>
    );
  }
};

const WrappedInProvider = (props: Props) => <GroupEventFeedback {...props} />;

export default WrappedInProvider;
